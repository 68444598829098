<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <ApeMenu :menus="menus" />
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'Performance',
  components: {
    PageHeaderLayout,
  },
  data() {
    return {
      menus: [
        {
          icon: 'mubiao',
          display_name: '项目组',
          url: '/mcn_performance/objectives',
          permission_name: 'pa_kpi_target__group'
        },
        {
          icon: 'fenpei',
          display_name: '总监',
          url: '/mcn_performance/director',
          permission_name: 'pa_kpi_target__gm'
        },
        {
          icon: 'bangdan',
          display_name: 'VP',
          url: '/mcn_performance/vp',
          permission_name: 'pa_kpi_target__vp'
        },
      ],
    }
  },
}
</script>

<style scoped></style>
